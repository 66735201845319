angular.module("app")
    .factory('userService', ["$rootScope", "$http", "$log", "$q", function ($rootScope, $http, $log, $q) {
        var urls = {
            signin: "/user/signin",
            signout: "/user/signout",
            authenticated: "/user/authenticated",
            organisation: "/dashboard/organisation",
            getActivation: function (token) {
                return sprintf("/user/activation/%s", token);
            },
            submitActivation: "/user/activate/",
            forgotPassword: "/user/forgot-password/",
            resetPassword: "/user/reset-password/",
            authorisation: "/user/authorisation/"
        };
        return {
            signIn: function (username, password) {
                return $http.post(urls.signin, {
                    username: username,
                    password: password
                }, {withCredentials: true})
                    ['catch'](function (resp) {
                    return resp;
                })
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            signOut: function () {
                return $http.post(urls.signout, {}, {withCredentials: true});
            },
            isAuthenticated: function () {
                return $http.get(urls.authenticated)
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            getOrganisation: function () {
                var defer = $q.defer();
                if (!_.isEmpty($rootScope.organisation)) {
                    defer.resolve($rootScope.organisation);
                } else {
                    $http.get(urls.organisation)
                        .then(function (resp) {
                            defer.resolve(resp.data);
                        });
                }
                return defer.promise;
            },
            getActivation: function (token) {
                var url = urls.getActivation(token);
                $log.info("found url %o", url);
                return $http.get(url)
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            submitActivation: function (activation) {
                return $http.post(urls.submitActivation, activation)
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            forgotPassword: function (email) {
                return $http.post(urls.forgotPassword, {email: email})
                    .then(function (resp) {
                        return resp.data;
                    });
            },
            resetPassword: function (activation, password) {
                return $http.post(urls.resetPassword, {
                        activationKey: activation,
                        password: password
                    })
                    .then(function (resp) {
                        return resp.data;
                    });
            }
        };
    }]);
